<template>
  <ui-modal
    :open="isOpen"
    :header="labels.title"
    :size="SIZES.small"
    closable
    data-test-id="invite-modal"
    @close="$emit('update:is-open', false)"
  >
    <div slot="body">
      <ui-alert
        v-if="errorMessage"
        data-test-id="modal-error"
        :color="COLORS.danger"
        class="py-1 mb-2"
      >
        {{ errorMessage }}
      </ui-alert>
      <ui-text-area
        :value="invitationEmails"
        :label="labels.textareaLabel"
        :size="SIZES.small"
        name="invitations"
        placeholder="johndoe@example.com, janedoe@example.com, ..."
        class="w-100 mt-3"
        rows="5"
        @changevalue="event => invitationEmails = event.detail"
      />
      <span>
        {{ labels.description }}
      </span>
    </div>
    <div
      slot="footer"
      class="d-flex flex-wrap justify-content-end"
      data-test-id="ui-modal--footer"
    >
      <ui-button
        v-bind="fetchButtonSpecs({ buttonType: THEME_BUTTON_TYPES.SECONDARY })"
        :disabled="isLoading"
        class="mr-3"
        data-test-id="close-button"
        @clickbutton="$emit('update:is-open', false)"
      >
        {{ $t('buttons.close') }}
      </ui-button>

      <ui-button
        v-bind="fetchButtonSpecs()"
        :disabled="!areEmailsValid"
        :loading="isLoading"
        data-test-id="send-button"
        @clickbutton="onClickSend"
      >
        {{ $t('buttons.send') }}
      </ui-button>
    </div>
  </ui-modal>
</template>

<script>
import noop from 'lodash/noop';
import trim from 'lodash/trim';
import split from 'lodash/split';
import size from 'lodash/size';
import reduce from 'lodash/reduce';

import {
  compactArray, isEmail,
  LOG_TYPE, logger,
} from '@emobg/web-utils';
import config from '@/config';
import { parseApiErrorMessage } from '@/utils/apiHelpers';
import { useTheme } from '@/composable/Theme/useTheme';

export default {
  name: 'InviteModalComponet',
  props: {
    isOpen: {
      type: Boolean,
      default: false,
    },
    action: {
      type: Function,
      default: noop,
    },
    labels: {
      type: Object,
      default: () => ({
        title: '',
        textareaLabel: '',
        description: '',
      }),
    },
  },

  setup() {
    const { fetchButtonSpecs } = useTheme();
    return { fetchButtonSpecs };
  },

  data() {
    return {
      invitationEmails: '',
      isLoading: false,
      errorMessage: undefined,
    };
  },

  computed: {
    isDisabled() {
      return this.isLoading || !this.areEmailsValid;
    },
    invitationsArray() {
      const invitationsSanitize = trim(this.invitationEmails.replace(/(\r\n|\n|\r)/gm, ''));
      return compactArray(split(invitationsSanitize, ','));
    },
    areEmailsValid() {
      return size(this.invitationsArray)
        && reduce(this.invitationsArray, (acc, invitationEmail) => acc && isEmail(trim(invitationEmail)).isValid, true);
    },
  },

  created() {
    this.appName = config.data.appName;
  },

  methods: {
    async onClickSend() {
      this.isLoading = true;
      this.errorMessage = undefined;

      try {
        await this.action({ emails: this.invitationsArray });
        this.$emit('update:is-open', false);
      } catch (error) {
        this.errorMessage = parseApiErrorMessage(this.$t, this.$i18n, error, 'invite_friend');
        logger.message(`There was a problem sending invitations via email: ${error.message}`, LOG_TYPE.error);
      } finally {
        this.isLoading = false;
      }
    },
  },
};

</script>
