<template>
  <MainLayoutComponent data-test-id="invite_friend-view">
    <NavBar />
    <div class="phInviteFriend LayoutPage container">
      <div class="row">
        <div class="col-6">
          <h2 class="mb-3">
            {{ $t('views.invite_friend.title') }}
          </h2>
          <div class="emobg-font-x-large emobg-font-weight-bold emobg-color-ink-light">
            {{ $t('views.invite_friend.subtitle') }}
          </div>
          <p class="my-6 mw-100 emobg-body-m-regular emobg-color-ink">
            {{ $t('views.invite_friend.paragraph', { amount: referralInfo.promo_amount, operator: appName }) }}
          </p>

          <div
            class="col-10 emobg-body-m-medium emobg-border-1 emobg-color-ink emobg-border-color-ground
            emobg-border-radius-small text-uppercase text-center p-2"
          >
            {{ referralInfo.promo_code }}
          </div>

          <div class="mt-6 mw-100">
            <div
              class="mb-5 pb-4 emobg-font-small emobg-font-weight-bold emobg-color-ink emobg-border-bottom-1
              emobg-border-color-ground"
            >
              {{ $t('views.invite_friend.share_on') }}:
            </div>

            <div>
              <ui-button
                v-for="socialNetwork of socialNetworks"
                :key="socialNetwork.name"
                :face="FACES.text"
                square
                class="mr-1"
                :data-test-id="`button_${socialNetwork.name}`"
                @clickbutton="share(socialNetwork.name)"
              >
                <ui-icon
                  :class="`SocialNetworks__icon--${socialNetwork.name}`"
                  :size="ICONS_SIZES.xLarge"
                  :icon="socialNetwork.icon"
                />
              </ui-button>
            </div>
          </div>
        </div>

        <div class="col-6">
          <div class="position-relative">
            <img
              class="position-absolute z-index-3"
              :style="carStyling"
              :src="carInviteImage"
              alt="car"
            >
            <div
              class="promo__amount text-center position-absolute emobg-color-white z-index-2"
              style="top: 25px; left: 250px;"
            >
              <div class="promo__wrapper">
                <h1 class="mb-0 emobg-font-weight-bold emobg-font-line-height-large emobg-color-white">
                  {{ referralInfo.promo_amount }}
                </h1>
                <p class="mb-0 emobg-font-line-height-large emobg-font-weight-bold emobg-font-line-height-large">
                  {{ $t('views.invite_friend.in_free_hours') }}
                </p>
              </div>
            </div>
            <img
              class="position-absolute z-index-1"
              :style="trailStyling"
              :src="carTrailImage"
              alt="line"
            >
          </div>
        </div>
      </div>
    </div>
    <InviteModalComponent
      :is-open="isModalOpen"
      :action="sendInvitations"
      :labels="modalLabels"
      @update:is-open="isOpen => toggleModal(isOpen)"
    />
  </MainLayoutComponent>
</template>

<script>
import get from 'lodash/get';

import config from '@/config';
import { isNilValue } from '@emobg/web-utils';
import MainLayoutComponent from '@/layouts/MainLayout/MainLayoutComponent';
import NavBar from '@Shared/components/Navbar/Navbar';
import InviteModalComponent from '@/components/Modals/InviteModal/InviteModalComponent';
// Mixins
import SegmentMixin from '@/mixins/Segment';
// Libs
import { openCenteredPopup } from '@/utils/popup';
import { parseApiErrorMessage } from '@/utils/apiHelpers';
// Constants
import { errorNotification } from '@/handlers/errorHandler.const';
import { SEGMENT_EVENTS } from '@/vue/constants';
import {
  FACEBOOK_URL, PINTEREST_URL, SOCIAL_NETWORKS, SOCIAL_PLATFORMS, TWITTER_URL,
} from '@/constants/social';
// Store
import { setLoaderStatus } from '@Shared/store/Loader/LoaderMapper';
import { fetchUserReferralInfo, referralInfo, sendReferralInvitations } from '@/stores/User/UserData/UserDataMapper';
import { useNotifications } from '@/composable/App/Notifications/useNotifications';
// Images
// TODO: When do you need one image from flavor, use ImportDynamicAssets
import { getThemedAssetByCategoryAndKey } from '@/utils/assetsRepository';
import { ASSETS_CATEGORY, PICTURES_KEYNAMES } from '@/constants/assetsRepository';

export default {
  name: 'InviteFriendView',
  components: {
    MainLayoutComponent,
    NavBar,
    InviteModalComponent,
  },

  mixins: [
    SegmentMixin,
  ],
  setup() {
    const { notifyError } = useNotifications();
    const carInviteImage = getThemedAssetByCategoryAndKey(ASSETS_CATEGORY.PICTURES, PICTURES_KEYNAMES.INVITE_FRIEND.car);
    const carTrailImage = getThemedAssetByCategoryAndKey(ASSETS_CATEGORY.PICTURES, PICTURES_KEYNAMES.INVITE_FRIEND.trail);
    const trailStyling = {
      top: '-50px',
      left: '-120px',
    };
    const carStyling = {
      top: '120px',
      left: 0,
    };
    return {
      carInviteImage,
      carTrailImage,
      trailStyling,
      carStyling,
      notifyError,
    };
  },
  data() {
    return {
      isModalOpen: false,
    };
  },

  computed: {
    referralInfo,

    confirmInvitationUrl() {
      const { origin } = window.location;
      const invitationCode = this.referralInfo.promo_code;

      const confirmInvitationPath = this.$router.resolve({
        name: 'ConfirmInvitationCode',
        params: { invitationCode },
      });

      return `${origin}${confirmInvitationPath.href}`;
    },

    referralMessage() {
      return get(this, 'referralInfo.texts.twitter');
    },

    modalLabels() {
      return {
        title: this.$t('modal.invite_friends.title'),
        textareaLabel: this.$t('modal.invite_friends.add_friends'),
        description: this.$t('modal.invite_friends.hint', { operator: this.appName }),
      };
    },
  },

  async created() {
    this.socialNetworks = SOCIAL_NETWORKS;
    this.appName = config.data.appName;
    try {
      await this.fetchUserReferralInfo();
    } catch (error) {
      this.notifyError(errorNotification({
        message: parseApiErrorMessage(this.$t, this.$i18n, error),
      }));
    }

    this.setLoaderStatus(false);
  },

  mounted() {
    this.trackSegment({
      name: SEGMENT_EVENTS.REFERRALS,
    });
  },

  methods: {
    setLoaderStatus,
    fetchUserReferralInfo,
    openCenteredPopup,
    sendReferralInvitations,

    openModalEmails() {
      this.isModalOpen = true;
    },
    toggleModal(isOpen) {
      this.isModalOpen = isNilValue(isOpen) ? !this.isModalOpen : isOpen;
    },
    shareFacebook() {
      const url = FACEBOOK_URL(this.confirmInvitationUrl);
      this.openCenteredPopup(url);
    },
    shareTwitter() {
      if (this.referralMessage) {
        const twitterText = encodeURIComponent(this.referralMessage);
        const url = TWITTER_URL(twitterText);
        this.openCenteredPopup(url);
      } else {
        this.notifyError(errorNotification({
          message: this.$t('notifications.whooops'),
        }));
      }
    },
    sharePinterest() {
      if (this.referralMessage) {
        const pinterestText = encodeURIComponent(this.referralMessage);
        const url = PINTEREST_URL(this.confirmInvitationUrl, pinterestText);
        this.openCenteredPopup(url);
      } else {
        this.notifyError(errorNotification({
          message: this.$t('notifications.whooops'),
        }));
      }
    },
    share(platform) {
      this.trackSegment({
        name: SEGMENT_EVENTS.REFERRALS_SHARED,
        data: {
          platform,
          referral_code: this.referralInfo.promo_code,
        },
      });

      const SHARING_ACTIONS = {
        [SOCIAL_PLATFORMS.facebook]: this.shareFacebook,
        [SOCIAL_PLATFORMS.twitter]: this.shareTwitter,
        [SOCIAL_PLATFORMS.pinterest]: this.sharePinterest,
        [SOCIAL_PLATFORMS.mail]: this.toggleModal,
      };

      return SHARING_ACTIONS[platform]();
    },
    sendInvitations({ emails }) {
      return this.sendReferralInvitations(emails);
    },
  },
};
</script>
